import React from 'react';
import PropTypes from 'prop-types';
import Badge from './Badge';
import { Link } from 'gatsby';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { apiBaseUrl } from '../../../shared/Helpers/axios';

const Articles = ({ articles }) => {
  if (!articles.length) return null;
  return (
    <div className="w-90-lg w-full mx-auto">
      <div className="container mx-auto py-12 px-6">
        <h2 className="font-lba text-5xl text-white mb-12-lg mb-8 text-center">
          Articles récents
        </h2>
        <div className="grid place-items-center grid-cols-3-lg grid-cols-2-md gap-7">
          {articles?.map((article) => {
            const createdAt = article?.attributes?.createdAt;
            const date = createdAt
              ? new Date(article.attributes?.createdAt).toLocaleDateString()
              : '';
            const formattedDate = date.split('/').join(' / ');
            return (
              <div
                key={article?.id}
                className="flex flex-col max-w-400-px max-w-full-lg w-full relative"
              >
                <img
                  src={`${apiBaseUrl}${article?.attributes?.cover?.data?.attributes?.url}`}
                  alt={article.attributes.title}
                  className="max-w-full w-full mb-3 h-200-px object-cover"
                />
                <div className="flex items-center justify-between w-full mb-2">
                  {article?.attributes?.category?.data?.attributes?.name && (
                    <Badge
                      bgColor={
                        article.attributes?.category?.data?.attributes?.color
                      }
                    >
                      {article?.attributes?.category?.data?.attributes?.name ??
                        ''}
                    </Badge>
                  )}
                  <span className="text-base text-white ms-auto">
                    {formattedDate ?? ''}
                  </span>
                </div>
                <h3 className="font-bold text-2xl text-white line-clamp-2 mb-3">
                  {article?.attributes?.title ?? ''}
                </h3>
                <p className="text-white text-base line-clamp-4">
                  <BlocksRenderer content={article?.attributes?.text ?? ''} />
                </p>
                <Link
                  to={`/articles/${article?.attributes?.category?.data?.attributes?.slug ?? ''}/${article?.attributes?.slug}`}
                  className="opacity-0 full-w-link absolute top-0 left-0 h-full w-full"
                >
                  {article?.attributes?.title}
                </Link>
              </div>
            );
          })}
        </div>

        <div className="w-full text-center mt-12">
          <Link
            to="/articles"
            className="font-bold text-sm text-white relative custom-underline text-center inline-block"
          >
            Tous les articles
          </Link>
        </div>
      </div>
    </div>
  );
};

Articles.propTypes = {
  articles: PropTypes.array,
};

export default Articles;
