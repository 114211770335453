import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({ id, name, label, type, placeholder }) => {
  return (
    <div className="w-full">
      <label htmlFor={id} className="hidden">
        {label}
      </label>
      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        className="bg-transparent outline-none border-0 placeholder-white py-4 border-bottom border-white-40 w-full text-white font-regular"
      />
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default TextInput;
