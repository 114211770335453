import React from 'react';
import PropTypes from 'prop-types';
import PageLayout from '../shared/PageLayout';
import Hero from '../page-components/homePage/Hero';
import Articles from '../page-components/homePage/Articles';
import AboutMe from '../page-components/homePage/AboutMe';
import Contact from '../page-components/homePage/Contact';
import axios from '../shared/Helpers/axios.js';

const IndexPage = ({ serverData }) => {
  const {
    data: { introduction, aboutMe, contact },
    categories,
    articles,
  } = serverData;
  return (
    <PageLayout contact={contact} categories={categories}>
      <Hero {...introduction} />
      <Articles articles={articles} />
      <AboutMe {...aboutMe} />
      <Contact />
    </PageLayout>
  );
};

export default IndexPage;

export async function getServerData() {
  try {
    const { data } = await axios.get('/api/home-page', {
      params: {
        populate: {
          introduction: {
            fields: ['title', 'description'],
            populate: {
              image: {
                fields: ['url'],
              },
            },
          },
          aboutMe: {
            fields: ['text'],
            populate: {
              image: {
                fields: ['url'],
              },
            },
          },
          contact: {
            fields: [
              'instagram',
              'facebook',
              'youtube',
              'twitter',
              'linkedin',
              'contactEmail',
            ],
          },
        },
      },
    });

    const { data: categoryData } = await axios.get('/api/categories', {
      params: {
        fields: ['name', 'slug'],
      },
    });

    const { data: articleData } = await axios.get('/api/articles', {
      params: {
        pagination: {
          pageSize: 9,
        },
        sort: 'createdAt:desc',
        populate: {
          category: {
            fields: ['name', 'slug', 'color'],
          },
          cover: {
            fields: ['url'],
          },
        },
      },
    });

    return {
      props: {
        categories: categoryData.data,
        articles: articleData.data,
        data: {
          ...data.data.attributes,
        },
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

IndexPage.propTypes = {
  serverData: PropTypes.object,
};

export const Head = () => <title>Home Page</title>;
