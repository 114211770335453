export const contactFormElements = [
  {
    id: 'firstName',
    name: 'firstName',
    type: 'text',
    label: 'Votre nom',
    placeholder: 'Votre nom*',
  },
  {
    id: 'lastName',
    name: 'lastName',
    type: 'text',
    label: 'Votre prénom',
    placeholder: 'Votre prénom*',
  },
  {
    id: 'email',
    name: 'email',
    type: 'text',
    label: 'Votre email',
    placeholder: 'Votre email*',
  },
  {
    id: 'subject',
    name: 'subject',
    type: 'text',
    label: 'Sujet',
    placeholder: 'Sujet',
  },
];
