import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { apiBaseUrl } from '../../../shared/Helpers/axios';

const AboutMe = ({ text, image }) => {
  return (
    <div
      id="introduction"
      className="w-90-lg max-w-500-px max-w-full-lg mx-auto"
    >
      <div className="container mx-auto my-8 relative px-6">
        <StaticImage
          src="../../../images/homepage/yellow-effect.png"
          layout="fixed"
          width={84}
          className="absolute -top-3 right-0 z-10"
        />
        <div className="grid grid-cols-2-lg">
          <img
            src={`${apiBaseUrl}${image?.data?.attributes?.url}`}
            alt=""
            className="max-w-full w-full aspect-square object-cover object-position-top"
            height={100}
          />
          <div className="h-full p-6 w-full bg-blue-200 flex items-center text-center">
            <p className="text-blue-900 font-semibold text-4xl-lg text-2xl">
              {text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

AboutMe.propTypes = {
  text: PropTypes.string,
  image: PropTypes.object,
};

export default AboutMe;
