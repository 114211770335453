import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { apiBaseUrl } from '../../../shared/Helpers/axios';

const Hero = ({ title, description, contactEmail, image }) => {
  return (
    <div className="w-full relative">
      <div className="absolute hidden flex-lg items-center gap-12 right-24-px top-50 rotate-90-x-50 trans-origin-center z-10">
        <span className="w-10-vw max-w-180-px h-1-px bg-white-50 inline-block" />
        <p className="text-white">{contactEmail}</p>
        <span className="w-10-vw max-w-180-px h-1-px bg-white-50 inline-block" />
      </div>
      <div className="w-90-lg w-full mx-auto py-10 py-0-lg">
        <div className="container mx-auto px-6">
          <div className="flex items-center-lg justify-between-lg flex-col flex-row-lg">
            <div className="w-35-lg">
              <span className="inline-block text-white font-lba text-6xl mb-6">
                {title}
              </span>
              <div className="text-white text-base mb-6">
                <BlocksRenderer content={description} />
              </div>
              <Link
                to="/"
                className="font-bold text-base text-white relative custom-underline"
              >
                Lire Plus
              </Link>
            </div>
            <div className="w-35-lg">
              <img
                src={`${apiBaseUrl}${image?.data?.attributes?.url}`}
                alt=""
                className="h-auto w-auto object-contain"
                width={520}
              />
              <div className="flex hidden-lg items-center justify-center gap-8 z-10 text-center">
                <span className="flex-1 max-w-180-px h-1-px bg-white-50 inline-block" />
                <p className="text-white">{contactEmail}</p>
                <span className="flex-1 max-w-180-px h-1-px bg-white-50 inline-block" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  contactEmail: PropTypes.string,
  image: PropTypes.object,
};

export default Hero;
