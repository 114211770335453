import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import TextInput from '../../../shared/UIKit/TextInput';
import { contactFormElements } from './static';

const Contact = () => {
  return (
    <div id="contact" className="w-90-lg w-full mx-auto">
      <div className="container mx-auto pt-8 pb-12 px-6">
        <h2 className="font-lba text-5xl text-white mb-6 text-center">
          Contact
        </h2>
        <div className="flex gap-4 pt-6-lg">
          <StaticImage
            src="../../../images/icons/left-feathers.svg"
            objectFit="contain"
            layout="fixed"
            className="hidden flex-lg"
          />
          <form className="flex-1 flex flex-col px-6-lg py-6-lg py-0 gap-4">
            {contactFormElements.map((el) => (
              <TextInput key={el.id} {...el} />
            ))}
            <div className="text-end mt-4">
              <button
                type="submit"
                className="bg-red px-5 py-3 text-black font-bold text-sm"
              >
                Envoyer
              </button>
            </div>
          </form>
          <StaticImage
            src="../../../images/icons/right-feathers.svg"
            objectFit="contain"
            layout="fixed"
            className="hidden flex-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
